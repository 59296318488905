.background-date-picker {
  background-image: url('../../../public/img/calendar.png');
  background-repeat: no-repeat;
  background-position: right;
  background-position-x: 95%;
}

.crop-image-selector input {
  margin: 0;
  padding: 0;
  appearance: none;
}

.crop-image-selector-2 input {
  position: absolute;
  z-index: 999;
}

.wide {
  background-image: url('../../../public/img/wide.png');
}

.med {
  background-image: url('../../../public/img/med.png');
}

.tight {
  background-image: url('../../../public/img/tight.png');
}

.image-crop {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100px;
  height: 70px;
  transition: all 100ms ease-in;

  // filter: brightness(1.8) grayscale(1) opacity(0.7);

  &:hover {
    filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  }
}

.crop-image-selector-2 input:active + .image-crop,
.crop-image-selector input:active + .image-crop {
  opacity: 0.9;
}

.crop-image-selector-2 input:checked + .image-crop,
.crop-image-selector input:checked + .image-crop {
  filter: none;
}

.center-div {
  text-align: -webkit-right !important;
}
